import { Button, Card, Divider, Dropdown, List, message, Popconfirm, Skeleton, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { url } from "../ config";
import VideoDescription from "./VideoDescription";
import { useQuery } from "../helpers/useQuery";
import InfiniteScroll from 'react-infinite-scroll-component';

const statuses = {
    'moderate': "На модерации",
    // 'uploaded': "Загружено",
    'processing': "На обработке",
    'processed': "Обработано",
    'uploaded': "На обработке",
}

const pageSize = 6;

const ModerateVideosPage = () => {
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [hasNext, setHasNext] = useState(false);
    const [pageIndex, setPageIndex] = useState(1)
    const query = useQuery();


    const fetchAllVideos = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        fetch(`${url}/videos/moderate?pageIndex=${pageIndex}&pageSize=${pageSize}`, { method: 'GET' }).then(async res => {
            const data = await res.json();
            setVideos(videos => [...videos,...data])
            setPageIndex((index) => index + 1)
            setHasNext(data.length === pageSize)
        }).catch(err => {
            message.error("Something went wrong!")
        }).finally(() => setLoading(false))
    }

    const deleteVideo = (id) => {
        fetch(`${url}/videos/${id}`, { method: 'DELETE' }).then(async res => {
            setVideos((videos)=> videos.filter(video => video.id !== id))
        }).catch(err => {
            message.error("Something went wrong!")
        })
    }

    const acceptVideo = (id) => {
        fetch(`${url}/videos/moderate/${id}`, { method: 'POST' }).then(async res => {
            setVideos((videos)=> videos.filter(video => video.id !== id))
            message.success('Успешно')
        }).catch(err => {
            message.error("Something went wrong!")
        })
    }

    useEffect(() => {
        fetchAllVideos()
    }, [])

    return (
        <div>
            <h2>Список видео на модерации</h2>
            <InfiniteScroll
                dataLength={videos.length}
                next={fetchAllVideos}
                hasMore={hasNext}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 3,
                    }}
                    dataSource={videos}
                    renderItem={(item) => (
                        <List.Item>
                            <Card title={
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <div>{item.filename}</div>
                                    <div style={{ marginLeft: 30 }}>
                                        <Button.Group>
                                            <Popconfirm title="Вы точно хотите удалить?" cancelText='Нет' okText='Да' onConfirm={() => deleteVideo(item.id)}>
                                                <Button danger>
                                                    <DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                            <Button onClick={() => acceptVideo(item.id)}><CheckCircleOutlined /></Button>
                                        </Button.Group>
                                    </div>
                                </div>
                            }>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <video width="320" height="240" controls={true}>
                                        <source src={`${url}/static/${item.filename}`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <Typography.Text style={{ marginTop: 10 }} keyboard>Статус: {statuses[item.status]}</Typography.Text>
                                    <Typography.Text style={{ marginTop: 10 }} keyboard>Трасса: {item.line_name}</Typography.Text>
                                    <VideoDescription videoId={item.id} description={item.description} />
                                    {/* <Button.Group style={{ marginTop: 10 }} ><Button danger>Отказать</Button></Button.Group> */}
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>
    );
};

export default ModerateVideosPage;