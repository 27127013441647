import { Button, Card, Divider, Dropdown, List, message, Popconfirm, Tabs, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { url } from "../ config";
import VideoDescription from "./VideoDescription";
import { useQuery } from "../helpers/useQuery";
import InfiniteScroll from "react-infinite-scroll-component";

const statuses = {
    'moderate': "На модерации",
    // 'uploaded': "Загружено",
    'processing': "На обработке",
    'processed': "Обработано",
    'uploaded': "На обработке",
}

const pageSize = 6;

const AllVideosPage = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    const [pageIndex, setPageIndex] = useState(1)

    const query = useQuery();

    const fetchAllVideos = () => {
        if (loading) {
            return;
        }
        console.log("fetch all videos");
        setLoading(true);
        fetch(`${url}/videos/all?pageIndex=${pageIndex}&pageSize=${pageSize}`, { method: 'GET' }).then(async res => {
            const data = await res.json();
            setVideos(videos => [...videos,...data])
            setPageIndex((index) => index + 1)
            setHasNext(data.length === pageSize)
        }).catch(err => {
            message.error("Something went wrong!")
        }).finally(() => setLoading(false))
    }

    const deleteVideo = (id) => {
        fetch(`${url}/videos/${id}`, { method: 'DELETE' }).then(async res => {
            setVideos((videos)=> videos.filter(video => video.id !== id))
        }).catch(err => {
            message.error("Something went wrong!")
        })
    }

    useEffect(() => {
        console.log("all videos");
        fetchAllVideos()
    }, [])

    console.log(videos.length);

    return (
        <div>
            <h2>Список всех файлов</h2>
            <Tabs
                centered
                items={[{
                    label: `Видео`,
                    key: '1',
                    children: 
                    <InfiniteScroll
                        dataLength={videos.length}
                        next={fetchAllVideos}
                        hasMore={hasNext}
                        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 2,
                                xl: 3,
                                xxl: 3,
                            }}
                            dataSource={videos}
                            renderItem={(item) => (
                                <List.Item key={item.filename}>
                                    <Card title={
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <div>{item.filename}</div>
                                            <div style={{ marginLeft: 30 }}>
                                                <Popconfirm title="Вы точно хотите удалить?" cancelText='Нет' okText='Да' onConfirm={() => deleteVideo(item.id)}>
                                                    <Button danger>
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <video key={item.filename} width="320" height="240" controls={true}>
                                                <source src={`https://mediastore.icmp.kz/api/static/${item.filename}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                            <Typography.Text style={{ marginTop: 10 }} keyboard>Статус: {statuses[item.status]}</Typography.Text>
                                            <Typography.Text style={{ marginTop: 10 }} keyboard>Трасса: {item.line_name}</Typography.Text>
                                            <Typography.Text style={{ marginTop: 10 }} keyboard>Количество точек: {item?.frames_count || 0}</Typography.Text>
                                            <VideoDescription videoId={item.id} description={item.description} />
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        /></InfiniteScroll>,
                }
                ]}
            />
        </div>
    );
};

export default AllVideosPage;