import { UploadOutlined } from "@ant-design/icons";
import { Button, Progress, Upload } from "antd";
import React, { useState } from "react";
import { url } from "./ config";

const FileUpload = ({ lineId }) => {
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);

  const handleFileUpload = (info) => {
    console.log(info, info.file.originFileObj);
    const selectedFile = info.file.originFileObj;
    if (!selectedFile) {
      alert("Выберите файл для загрузки.");
      return;
    }

    const chunkSize = 5 * 1024 * 1024; // 5MB (adjust based on your requirements)
    const totalChunks = Math.ceil(selectedFile.size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;
    let end = 0;

    const uploadNextChunk = async () => {
      console.log(start, end, selectedFile.size);
      if (end <= selectedFile.size || start < selectedFile.size) {
        console.log("SENDING");
        const chunk = selectedFile.slice(start, end);
        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("chunkNumber", chunkNumber);
        formData.append("totalChunks", totalChunks);
        formData.append("originalname", selectedFile.name);
        formData.append("lineId", lineId);
        fetch(`${url}/upload`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log({ data });
            const temp = `Загружаем видео...`;
            setStatus(temp);
            setProgress(Number((chunkNumber + 1) * chunkProgress));
            console.log(temp);
            chunkNumber++;
            start = end;
            end = start + chunkSize;
            uploadNextChunk();
          })
          .catch((error) => {
            console.error("Error uploading chunk:", error);
          });
      } else {
        setProgress(100);
        setStatus("Файл отправлен на модерацию");
      }
    };

    uploadNextChunk();
  };

  return (
    <div style={{ width: '95%', display: 'flex', alignItems: 'center', marginBottom: 20 }}>
      <Upload showUploadList={false} fileList={[]} onChange={handleFileUpload}>
        <Button icon={<UploadOutlined />}>Загрузить видео</Button>
      </Upload>
      <h3>{status}</h3>
      {progress > 0 && <Progress percent={progress.toFixed(2)} />}
    </div>
  );
};

export default FileUpload;