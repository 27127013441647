import { Button, ColorPicker, List, message, Popconfirm } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import LinesVideosPage from "../videos/LinesVideosPage";
import CreateLineForm from "./CreateLineForm";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import UpdateLineForm from "./UpdateLineForm";
import FileUpload from "../FileUpload";
import { url } from "../ config";
import { useQuery } from "../helpers/useQuery";

const LinesPage = () => {
  const [lines, setLines] = useState([]);
  const query = useQuery();

  useEffect(() => {
    fetch(`${url}/lines`, { method: "GET" })
      .then(async (res) => {
        setLines(await res.json());
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong!");
      });
  }, [query.get("source") == "lines"]);

  const onCreate = (values) => {
    setLines((l) => {
      console.log(l, values, [...l, values]);
      return [...l, values];
    });
  };

  const onUpdate = (values) => {
    setLines((l) => {
      console.log(l, values, [...l, values]);
      return l.map((l) => (l.id === values.id ? { ...l, ...values } : l));
    });
  };

  const deleteLine = (id) => {
    fetch(`${url}/lines/` + id, { method: "DELETE" })
      .then(async (res) => {
        setLines((l) => l.filter((i) => i.id !== id));
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: 40,
          }}
        >
          <div style={{ width: 100 }}></div>
          <h2>Список трасс</h2>
          <CreateLineForm onCreate={onCreate} />
        </div>
        <List
          itemLayout="horizontal"
          dataSource={lines}
          bordered
          renderItem={(item, index) => (
            <List.Item>
              <Button.Group>
                <Popconfirm
                  title="Вы точно хотите удалить?"
                  cancelText="Нет"
                  okText="Да"
                  onConfirm={() => deleteLine(item.id)}
                >
                  <Button danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
                <UpdateLineForm onUpdate={onUpdate} initialValues={item} />
              </Button.Group>
              <List.Item.Meta
                title={
                  <Link to={`/line/${item.id}?name=${item.name}&source=lines`}>
                    Трасса - {item.name}
                  </Link>
                }
                description={`Количества видео в трассе: ${item.video?.length}`}
              />
              <ColorPicker showText={true} value={item.color} />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default LinesPage;
