import { ColorPicker, Form, Input, List, message, Button, Modal } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import LinesVideosPage from "../videos/LinesVideosPage";
import { EditOutlined } from "@ant-design/icons";
import { isString } from "antd/es/button";
import { url } from "../ config";

const UpdateLineForm = ({ onUpdate, initialValues }) => {
    const [visible, setVisible] = useState(false)


    const onFinish = (values) => {
        fetch(`${url}/lines/`+initialValues.id, { method: 'PUT', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({name:values.name, color: isString(values.color) ? values.color : `#${values.color.toHex()}`})  }).then(async res => {
            onUpdate(await res.json())
            setVisible(false);
        }).catch(err => {
            message.error("Something went wrong!")
        })
    }

    return (
        <>
            <Button onClick={()=> setVisible(true)}><EditOutlined /></Button>
            <Modal onCancel={()=> setVisible(false)} open={visible} okButtonProps={{style: {opacity: 0}}} cancelButtonProps={{style: {opacity: 0}}}>
                <Form
                    onFinish={onFinish}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={initialValues}
                    autoComplete="off"
                >
                    <Form.Item
                        style={{marginTop:30}}
                        label="Наименование"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Введите имя!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Цвет"
                        name="color"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите цвет!',
                            },
                        ]}
                    >
                        <ColorPicker />
                    </Form.Item>


                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default UpdateLineForm;