import {
  Button,
  Card,
  Divider,
  Dropdown,
  Select,
  message,
  Popconfirm,
  Tabs,
  Typography,
  Tree,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { url } from "../ config";
import { useDebouncedCallback } from "use-debounce";

const ExportLayerPage = () => {
  const [layers, setLayers] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchText, setSeacrhText] = useState();
  const [selectedLayer, setSelectedLayer] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [layerComponents, setLayerComponents] = useState();

  const debouncedSearchLayer = useDebouncedCallback((text) => {
    fetch(`${url}/layers/search?search=` + text, {
      method: "GET",
    })
      .then(async (res) => setLayers(await res.json()))
      .catch((err) => {
        message.error("Something went wrong!");
      });
  }, 1000);

  const getLayerCities = async () => {
    fetch(`${url}/layers/cities/${selectedLayer}`, {
      method: "GET",
    })
      .then(async (res) => {
        const result = await res.json();
        if (result.length) setCities(result);
        else message.warning("Нет городов у данного слоя");
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };

  const getLayerComponentsByCity = async () => {
    fetch(
      `${url}/layers/components/${selectedLayer}?${selectedCity
        ?.map((id) => `cityId=${id}`)
        .join("&")}`,
      {
        method: "GET",
      }
    )
      .then(async (res) => {
        const result = await res.json();
        setLayerComponents(result);
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };

  useEffect(() => {
    console.log("WWS", selectedLayer);
    if (selectedLayer) {
      getLayerCities();
      setSelectedCity();
    }
  }, [selectedLayer]);

  useEffect(() => {
    console.log("wws", searchText);
    debouncedSearchLayer(searchText);
  }, [searchText]);

  console.log("gg", layerComponents?.antennas[0]?.count);

  return (
    <div>
      <Select
        showSearch
        style={{ width: 550 }}
        value={selectedLayer}
        placeholder={"Название или категорию слоя"}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={searchText?.length ? undefined : null}
        onChange={setSelectedLayer}
        onSearch={setSeacrhText}
        options={(layers || []).map((layer) => ({
          value: layer.id,
          label: `${layer.category} - ${layer.name}`,
        }))}
      />

      {/* {cities.length && (
        <Select
          style={{ width: 550 }}
          value={selectedCity}
          placeholder={"Выберите город"}
          filterOption={false}
          onChange={setSelectedCity}
          options={cities.map((city) => ({
            value: city.id,
            label: city.name,
          }))}
        />
      )} */}

      {cities.length ? (
        <Tree
          checkable
          style={{ width: 550 }}
          value={selectedCity}
          placeholder={"Выберите город"}
          onCheck={setSelectedCity}
          treeData={cities.map((city) => ({
            key: city.id,
            title: city.name,
          }))}
        />
      ) : null}

      {selectedCity?.length && (
        <Button onClick={getLayerComponentsByCity} type="primary">Получить данные</Button>
      )}

      {layerComponents ? (
        <div style={{ width: "50%", textAlign: "left", marginLeft:24 }}>
          <div>
            {layerComponents?.antennas[0]?.count !== "0" ? (
              <Checkbox>
                Антенны ({layerComponents?.antennas[0]?.count})
              </Checkbox>
            ) : null}
          </div>
          <div>
            {layerComponents?.pipestands[0]?.count !== "0" ? (
              <Checkbox>
                Трубостоки ({layerComponents?.pipestands[0]?.count})
              </Checkbox>
            ) : null}
          </div>
        </div>
      ) : <></>}

      {layerComponents?.apks?.length > 0 ? (
        <Tree
          checkable
          onSelect={(g) => {
            console.log(g);
          }}
          onCheck={(g) => {
            console.log(g);
          }}
          treeData={[
            {
              title: "АПК",
              children: layerComponents?.apks.map((apk) => ({
                title: `${apk.name} (${apk.count})`,
                key: apk.id,
              })),
            },
          ]}
        />
      ) : null}

      {layerComponents?.fiberObjects?.length > 0 ? (
        <Tree
          checkable
          onSelect={(g) => {
            console.log(g);
          }}
          onCheck={(g) => {
            console.log(g);
          }}
          treeData={[
            {
              title: "Объекты",
              children:
                layerComponents?.fiberObjects?.map((fiberObject) => ({
                  title: `${fiberObject?.name} (${fiberObject?.count})`,
                  key: fiberObject.id,
                })) || [],
            },
          ]}
        />
      ) : (
        <></>
      )}

      {Object.keys(layerComponents?.lines || {})?.length > 0 ? (
        <Tree
          checkable
          onSelect={(g) => {
            console.log(g);
          }}
          onCheck={(g) => {
            console.log(g);
          }}
          treeData={[
            {
              title: "Линии",
              children: Object.values(layerComponents?.lines).map((line) => {
                return {
                  title: line.type,
                  children: line.children.map((type) => ({
                    title: `${type.name} (${type.count})`,
                    key: `${line.type}-${type.cableTypeId}`,
                  })),
                };
              }),
            },
          ]}
        />
      ) : null}

      {Object.keys(layerComponents?.baseStations || {}).length > 0 ? (
        <Tree
          checkable
          onSelect={(g) => {
            console.log(g);
          }}
          onCheck={(g) => {
            console.log(g);
          }}
          treeData={[
            {
              title: "Узел связи",
              children: Object.values(layerComponents?.baseStations).map(
                (bs) => {
                  return {
                    title: `${bs.base_type} (${bs.count})`,
                    key: bs.base_type,
                  };
                }
              ),
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default ExportLayerPage;
