import React from 'react';
import { Input, message } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { url } from '../ config';

const VideoDescription = ({ videoId, description }) => {
    console.log(description);
    
    const debounced = useDebouncedCallback(
        (value) => {
            fetch(`${url}/videos/description/`+videoId, { method: 'PATCH', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({description: value})})
            .catch(err => {
                message.error("Something went wrong!")
            })
        },
        1000
      );
    
    const onChangeText = (e) => {
        debounced(e.target.value)
    }

    return <Input.TextArea style={{marginTop: 10, width: 350}} placeholder='Описание' defaultValue={description} onChange={onChangeText}></Input.TextArea>
};

export default VideoDescription;